@font-face {
    font-family: 'Segoe UI';
    src: url('./assets/fonts/Segoe\ UI.ttf') format('truetype');
}

:root {
    --white-color: #fff;
    --black-color: #060403;
    --grey-color: #77787b;
    --minimal-color: #f7f7f7;

    --blue-color-100: #1c7fc2;
    --blue-color-80: #4999ce;
    --blue-color-60: #77b2da;
    --blue-color-40: #a4cce7;
    --blue-color-20: #d2e5f3;

    --yellow-color-100: #ffa437;
    --yellow-color-20: #ffc887;
    --yellow-color-10: #ffe9ce;

    --red-color-100: #ff4a55;
    --red-color-20: #ffc0c4;

    --green-color: #45c01a;
    --green-color: #bbebc4;

    --grey-color-100: #00070c;
    --grey-color-80: #33393c;
    --grey-color-60: #666a6d;
    --grey-color-40: #999c9e;
    --grey-color-20: #cccdce;

    --alert-green-color: #45c01a;
    --alert-red-color: #f12323;
    --alert-yellow-color: #ffab01;
}

* {
    margin: 0;
    padding: 0;
    color: var(--black-color);
    box-sizing: border-box;
    font-family: "Segoe UI";
}



span {
    overflow: hidden;
    text-overflow: ellipsis;
}

h1,
h2,
h3,
h4 {
    margin: 0;
    font-style: normal;
}

.d-none{
    display: none !important;
}

.error{
    color: var(--red-color-100);
}

.modal-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 1s ease;
}

.modal-show {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s ease, visibility 1s ease;
    z-index: 20 !important;
}

.h1-regular {
    font-size: 64px;
    font-weight: 400;
    line-height: 72px;
}

.h1-bold {
    font-size: 64px;
    font-weight: 700;
    line-height: 72px;
}

.h2-regular {
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
}

.h2-bold {
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
}

.h3-regular {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
}

.h3-bold {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
}

.s1-bold {
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
}

.s2-regular {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
}

.s2-bold {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

.s3-regular {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
}

.s3-bold {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}

.s4-regular {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

.s4-bold {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.b1-regular {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

.b1-bold {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
}

.b2-regular {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.b2-bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.b3-regular {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.b3-bold {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.c1-regular {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.c1-bold {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
}

::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}



::-webkit-scrollbar-thumb {
    background-color: var(--grey-color-20);
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}